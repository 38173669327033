import { Component, OnInit, ViewChild } from "@angular/core";
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { SopService } from "../../Shared/sop.service";
import { Constant } from "../../../Shared/Models/constant";
import { ToastrService } from "ngx-toastr";
import { BranchService } from "../../Shared/branch.service";
import { AuthService } from "../../../Shared/Auth/auth.service";
import { JobsService } from "../../Shared/jobs.service";
import { ModalDirective } from "ngx-bootstrap/modal";
import { NgxImageCompressService } from "ngx-image-compress";
import { CarouselConfig } from "ngx-bootstrap/carousel";
import { Lightbox } from "ngx-lightbox";
import Swal from "sweetalert2";
import * as ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { DatePipe } from '@angular/common';
@Component({
    selector: "app-survey-link",
    templateUrl: "./repair-survey-link.component.html",
    styleUrls: ["./repair-survey-link.component.scss"],
})
export class RepairSurveyLinkComponent implements OnInit {
    private _album: Array<any> = [];
    capture: any;
    submitted = false;
    productForm: FormGroup;
    isVideoActive = false;
    SOPName = "Image & Documents";
    EditActive = true;
    public Editor = ClassicEditor;
    Add_image_field;
    Document_field;
    EditForm: any;
    id;
    constant: any;
    imageURL: any;
    isAdmin = this.authService.isAllowedAccess("admin");
    FilterImageArray: Array<any> = [];
    ImageArray: Array<any> = [];
    @ViewChild("primaryModal") public primaryModal: ModalDirective;

    @ViewChild("Gallery") public Gallery: ModalDirective;

    constructor(
        private fb: FormBuilder,
        private route: ActivatedRoute,
        private SOPService: SopService,
        private router: Router,
        private toastr: ToastrService,
        private BranchService: BranchService,
        private authService: AuthService,
        private JobsService: JobsService,
        private imageCompress: NgxImageCompressService,
        private _lightbox: Lightbox,
        private datePipe: DatePipe
    ) {
        this.productForm = this.fb.group({
            form_field: this.fb.array([]),
            job_remark: [],
            video_file: "",
        });
    }

    ngOnInit(): void {
        this.constant = new Constant();
        this.id = this.route.snapshot.paramMap.get("id");
        this.GetImageDocumentDetails(this.id);
        this.MenuSelectionManagement("NavItem2");
        // this.JobsService.JobDetail(this.id)
        // .subscribe(data => {
        //   let Jobeditdetail= data.result;
        //   this.GetImageDocumentDetails(Jobeditdetail['id']);
        // });
        // if(this.isAdmin){
        //   this.getBranchList();
        // }else{
        //   this.branchList = JSON.parse(localStorage.getItem('SubAdminBranch'));
        // }
    }
    files: any;
    options: any;

    newFormField(value = ""): FormGroup {
        return this.fb.group({
            form_field_label: [value, Validators.required],
        });
    }
    newDocumentField(value = "") {
        return this.fb.group({
            form_document_label: [value, Validators.required],
        });
    }
    addFormField(value = "", name = undefined, custom) {
        if (!name || name.replace(/^[ ]+/g, "") == "") {
            this.toastr.warning("Please Enter a Sop Name", "Validation");
            return;
        }
        let newValue =
            value == "assets/img/no-image.jpg"
                ? ""
                : value.replace(/^[ ]+/g, "");
        if (custom) {
            var valueArr = this.ImageArray.map(function (item) {
                return item.form_field_label?.name
                    ? item.form_field_label.name.toUpperCase()
                    : "";
            });

            if (valueArr.length > 0) {
                var isDuplicateForm = valueArr.some(function (item, idx) {
                    return valueArr.indexOf(name.toUpperCase()) == -1;
                });
                if (!isDuplicateForm) {
                    this.toastr.warning("Please Dont Repeat Sop Image Name");
                    this.Add_image_field = "";
                    return;
                }
            }
        }
        this.formFieldArray().push(this.newFormField(newValue));
        this._album.push({
            src: value,
            caption: name ? name : this.Add_image_field.replace(/^[ ]+/g, ""),
            thumb: value,
        });
        this.ImageArray.push({
            form_field_label: {
                src: value,
                name: name ? name : this.Add_image_field.replace(/^[ ]+/g, ""),
                custom: custom,
            },
        });
        this.Add_image_field = "";
    }

    addDocumentField(value = "", name = undefined, custom) {
        if (!name || name.replace(/^[ ]+/g, "") == "") {
            this.toastr.warning("Please Enter a Sop Name", "Validation");
            return;
        }
        let newValue =
            value == "assets/img/no-image.jpg"
                ? ""
                : value.replace(/^[ ]+/g, "");
        if (custom) {
            var valueArr = this.ImageArray.map(function (item) {
                return item.form_document_label?.name
                    ? item.form_document_label.name.toUpperCase()
                    : "";
            });
            var isDuplicateForm = valueArr.some(function (item, idx) {
                return valueArr.indexOf(name.toUpperCase()) == -1;
            });
            if (!isDuplicateForm) {
                this.toastr.warning("Please Dont Repeat Sop Image Name");
                this.Document_field = "";
                return;
            }
        }
        this.formFieldArray().push(this.newDocumentField(newValue));
        this._album.push({
            src: value,
            caption: name ? name : this.Document_field.replace(/^[ ]+/g, ""),
            thumb: value,
        });
        this.ImageArray.push({
            form_document_label: {
                src: value,
                name: name ? name : this.Document_field.replace(/^[ ]+/g, ""),
                custom: custom,
            },
        });
        this.Document_field = "";
    }

    removeQuantity(i: number) {
        Swal.fire({
            title: "Are you sure?",
            text: "You want to delete this sop!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#5d87ff",
            cancelButtonColor: "#f64846",
            confirmButtonText: "Yes",
        }).then((result) => {
            if (result.isConfirmed) {
                this.formFieldArray().removeAt(i);
                this._album.splice(i, i);
                this.ImageArray.splice(i, 1);
            }
        });
    }
    formFieldArray(): FormArray {
        return this.productForm.get("form_field") as FormArray;
    }
    onFileChange(event) {
        if (event.target.files.length > 0) {
                       var vid = document.createElement("video");
            const file = event.target.files[0];
            var fileURL = URL.createObjectURL(event.target.files[0]);
            vid.src = fileURL;
            vid.preload = "metadata";
            vid.onloadedmetadata = () => {
                window.URL.revokeObjectURL(vid.src);
                if (vid.duration < 30) {
                    this.productForm.patchValue({
                        video_file: file,
                    });
                } else {
                    Swal.fire({
                        title: "Oops!",
                        icon: "error",
                        text: "Video duration should be less than 30 seconds",
                        showConfirmButton: true,
                    }).then(function () {});
                    event.target.value = "";
                }
            };
        }
    }

    drop(event: CdkDragDrop<string[]>) {
        moveItemInArray(
            this.formFieldArray().controls,
            event.previousIndex,
            event.currentIndex
        );
        moveItemInArray(
            this.formFieldArray().value,
            event.previousIndex,
            event.currentIndex
        );
    }
    showPreview(event) {
        console.log(event);
        const file = (event.target as HTMLInputElement).files[0];
        const reader = new FileReader();
        reader.onload = () => {
            this.imageURL = reader.result as string;
        };
        reader.readAsDataURL(file);
        this.primaryModal.show();
    }
    compressFile(index, section, item) {
        this.imageCompress.uploadFile().then(({ image, orientation }) => {
            //this.imgResultBeforeCompress = image;
            let ratio =
                this.imageCompress.byteCount(image) / 1000 > 400 ? 50 : 100;
            console.log(ratio);

            this.imageCompress
                .compressFile(image, orientation, ratio, 100)
                .then((result) => {
                    this.constant.showLoader();
                    let file = {
                        file: result,
                        is_document:section=='form_document_label'?1:0
                    };
                    this.JobsService.uploadJobDoc(file).subscribe(
                        (data) => {
                            this.constant.hideLoader();
                            item.setValue(data.message.file_name);
                            this.ImageArray[index][section].src =
                                data.message.path;
                            this._album[index].src = data.message.path;
                            this._album[index].caption = data.message.file_name;
                            this._album[index].thumb = data.message.path;
                        },
                        (error) => {
                            if (error.status == 401) {
                                console.log(error.message);
                                this.router.navigate(["/login"]);
                            }
                            this.constant.hideLoader();
                        }
                    );

                    console.warn(
                        "Size in bytes is now:",
                        this.imageCompress.byteCount(result)
                    );
                });
        });
    }
    fileChange(event, index, section, item) {
        if (event.target.files.length > 0) {
            const reader = new FileReader();
            reader.readAsDataURL(event.target.files[0]);
            reader.onload = function () {
                let image: any = reader.result;

                let ratio = event.target.files[0].size / 1000 > 400 ? 50 : 100;
                let quality =
                    event.target.files[0].size / 1000 > 400 ? 80 : 100;
                this.imageCompress
                    .compressFile(image, -1, ratio, quality)
                    .then((result) => {
                        this.constant.showLoader();
                        let file = {
                            file: result,
                            is_document:section=='form_document_label'?1:0
                        };
                        this.JobsService.uploadJobDoc(file).subscribe(
                            (data) => {
                                this.constant.hideLoader();
                                item.setValue(data.message.file_name);
                                this.ImageArray[index][section].src =
                                    data.message.path;
                                this._album[index].src = data.message.path;
                                this._album[index].caption =
                                    data.message.file_name;
                                this._album[index].thumb = data.message.path;
                            },
                            (error) => {
                                if (error.status == 401) {
                                    console.log(error.message);
                                    this.router.navigate(["/login"]);
                                }
                                this.constant.hideLoader();
                            }
                        );

                        console.warn(
                            "Size in bytes is now:" +
                                this.imageCompress.byteCount(result) / 1000
                        );
                    });
            }.bind(this);
        }
    }

    Submit() {
        console.log(this.ImageArray);
        this.submitted = true;
        console.log(this.productForm.invalid);
        if (this.productForm.invalid) {
            return;
        } else {
            let formData = JSON.parse(JSON.stringify(this.productForm.value));
            formData.can_record_video = this.productForm.value.form_video_label
                ? 1
                : 0;

            formData.document_images_field_post = [];
            formData.vehichle_images_field_post = [];
            formData.custom_vehichle_images_field_post = [];
            formData.custom_document_images_field_post = [];

            this.ImageArray.map((val) => {
                if (val.form_field_label && !val.form_field_label.custom) {
                    var obj = {};
                    obj[val.form_field_label.name] = val.form_field_label.src;
                    formData.vehichle_images_field_post.push(obj);
                } else if (
                    val.form_document_label &&
                    !val.form_document_label.custom
                ) {
                    var obj = {};
                    obj[val.form_document_label.name] =
                        val.form_document_label.src;
                    formData.document_images_field_post.push(obj);
                } else if (
                    val.form_field_label &&
                    val.form_field_label.custom
                ) {
                    var obj = {};
                    obj[val.form_field_label.name] = val.form_field_label.src;
                    formData.custom_vehichle_images_field_post.push(obj);
                } else if (
                    val.form_document_label &&
                    val.form_document_label.custom
                ) {
                    var obj = {};
                    obj[val.form_document_label.name] =
                        val.form_document_label.src;
                    formData.custom_document_images_field_post.push(obj);
                }
            });

            if (formData.vehichle_images_field_post.length == 0) {
                this.toastr.warning("Please Add Images Field");
                return;
            }
            if (formData.document_images_field_post.length == 0) {
                this.toastr.warning("Please Add document Field");
                return;
            }
            delete formData.form_field;
            delete formData.form_video_label;
            formData.job_id = this.EditForm.job_id;
            formData.sop_id = this.EditForm.sop_id;
            const formDatavalue = new FormData();
            Object.keys(formData).map((value) => {
                if (value !== "video_file") {
                    if (Array.isArray(formData[value])) {
                        formDatavalue.append(
                            value,
                            JSON.stringify(formData[value])
                        );
                    } else {
                        formDatavalue.append(value, formData[value]);
                    }
                } else {
                    formDatavalue.append(
                        value,
                        this.productForm.get("video_file").value
                    );
                }
            });

            this.constant.showLoader();

            this.SOPService.SaveSurveyDetails(formDatavalue).subscribe(
                (data) => {
                    if (data.success == true) {
                        this.toastr.success(data.message, "Sucess");
                        Swal.fire({
                            title: "Success!",
                            icon: "success",
                            text: "Your Images & Documents submitted successfully, please close this tab now.",
                            allowOutsideClick: false,
                            showCancelButton: false,
                            showConfirmButton: false,
                        }).then(
                            function () {}
                            // handling the promise rejection
                        );
                    } else {
                        this.toastr.warning(data.message);
                    }
                    this.constant.hideLoader();
                },
                (error) => {
                    if (error.status == 401) {
                        this.router.navigate(["/login"]);
                    } else {
                        this.toastr.warning(error.error.message, "Oops");
                    }
                    this.constant.hideLoader();
                }
            );
        }
    }
    GetImageDocumentDetails(id) {
        this.constant.showLoader();

        this.SOPService.GetSurveyDetails(id).subscribe(
            (data) => {
                this.constant.hideLoader();
                this.EditForm = data.result;
                this.isVideoActive = this.EditForm.can_record_video;
                this.capture =
                    this.EditForm.upload_type == 2 ? "environment" : false;
                this.SOPName =
                    this.EditForm.insured_name +
                    " " +
                    this.EditForm.vehicle_reg_no;
                let vehichle_images_field_label = JSON.parse(
                    data.result.vehichle_images_field_label
                );
                let document_image_field_label = JSON.parse(
                    data.result.document_image_field_label
                );
                console.log(document_image_field_label);

                vehichle_images_field_label.map(
                    function (value) {
                        this.addFormField(
                            Object.values(value)[0],
                            Object.keys(value)[0],
                            false
                        );
                    }.bind(this)
                );
                if (document_image_field_label) {
                    document_image_field_label.map(
                        function (value) {
                            this.addDocumentField(
                                Object.values(value)[0],
                                Object.keys(value)[0],
                                false
                            );
                        }.bind(this)
                    );
                }
                if (data.result.custom_vehichle_images_field_label) {
                    let custom_vehichle_images_field_label = JSON.parse(
                        data.result.custom_vehichle_images_field_label
                    );
                    custom_vehichle_images_field_label.map(
                        function (value) {
                            this.addFormField(
                                Object.values(value)[0],
                                Object.keys(value)[0],
                                true
                            );
                        }.bind(this)
                    );
                }
                if (data.result.custom_document_image_field_label) {
                    let custom_document_image_field_label = JSON.parse(
                        data.result.custom_document_image_field_label
                    );
                    custom_document_image_field_label.map(
                        function (value) {
                            this.addDocumentField(
                                Object.values(value)[0],
                                Object.keys(value)[0],
                                true
                            );
                        }.bind(this)
                    );
                }
            },
            (error) => {
                console.log(error);
                if (error.status == 401) {
                    this.router.navigate(["/login"]);
                } else if (error.status == 404) {
                    Swal.fire({
                        title: "Oops!",
                        icon: "error",
                        text: error.error.message,
                        allowOutsideClick: false,
                        showCancelButton: false,
                        showConfirmButton: false,
                    }).then(
                        function () {}
                        // handling the promise rejection
                    );
                }
                this.constant.hideLoader();
            }
        );
    }
    //Gallery.show()
    GalleryList(section, path, index) {
        if (path == "assets/img/no-image.jpg") return false;
        this.FilterImageArray = [];
        this.ImageArray.map((val, i) => {
            if (
                val[section] &&
                val[section]["src"] != "assets/img/no-image.jpg"
            ) {
                this.FilterImageArray.push(val[section]);
            } else if (
                val[section] &&
                val[section]["src"] == "assets/img/no-image.jpg"
            ) {
            }
        });
        console.log(this.FilterImageArray, index);
        if (this.FilterImageArray.length > 0) {
            //  this.Gallery.show();
            this._lightbox.open(this.FilterImageArray, index);
        }
    }

    MenuSelectionManagement(id) {
        $(".nav-item .nav-link").removeClass("SideBarActiveMenu");
        $("#" + id).addClass("SideBarActiveMenu");
    }

    open(index: number): void {
        // open lightbox
        this._lightbox.open(this._album, index);
    }

    close(): void {
        // close lightbox programmatically
        this._lightbox.close();
    }
}
