import { navItems } from '../../_nav';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl,AbstractControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from "@angular/router";
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { timeout } from 'rxjs/operators';
import swal from 'sweetalert2';
import { LogoutService } from '../../Shared/AdminService/logout/logout.service';
import { AdminService } from '../../Shared/AdminService/Admin/admin.service';
import { ToastrService } from 'ngx-toastr';
import * as $ from 'jquery';

@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html'
})
export class DefaultLayoutComponent {

  isRootAdmin: String = "false";
  isSuperAdmin: String = "false";
  isAdmin: String = "false";

  id:any = 0;

  public sidebarMinimized = false;
  
  public navMenuItems = [
    {
      id:'NavItem7',
      name: 'Dashboard',
      url: '/dashboard',
      icon: 'fa fa-tachometer',
      IsSuperAdmin: true,
      IsRootAdmin: true,
      IsAdmin:true
    },
    {
      id:'NavItem3',
      name: 'Completed Jobs',
      url: '/Super-Admin/complete-jobs',
      icon: 'icon-list',
      IsSuperAdmin:true,
      IsRootAdmin: false,
      IsAdmin:true
     },
    {
      id:'NavItem1',
      name: 'Jobs',
      url: '/Jobs/Jobs',
      icon: 'icon-list',
      IsSuperAdmin: false,
      IsRootAdmin: false,
      IsAdmin:true
    },
    {
      id:'NavItem9',
      name: 'Jobs',
      url: '/Jobs/Jobs',
      icon: 'icon-list',
      IsSuperAdmin: true,
      IsRootAdmin: false,
      IsAdmin:false
    },
    {
      id:'NavItem2',
      name: 'Employees',
      url: '/Employee/Employee',
      icon: 'icon-people',
      IsSuperAdmin: false,
      IsRootAdmin: false,
      IsAdmin:true
    },

    {
      id:'NavItem10',
      name: 'Employees',
      url: '/Employee/Employee',
      icon: 'icon-people',
      IsSuperAdmin: true,
      IsRootAdmin: false,
      IsAdmin:false
    },
    {
      id:'NavItem11',
      name: 'Clients',
      url: '/Clients/client-master',
      icon: 'icon-user-follow',
      IsSuperAdmin: true,
      IsRootAdmin: false,
      IsAdmin:true
    },
    {
      id:'NavItem4',
      name: 'Admin',
      url: '/Super-Admin/Admin-Master',
      icon: 'icon-list',
      IsSuperAdmin: false,
      IsRootAdmin: true,
      IsAdmin:false
    },
    {
      id:'NavItem4',
      name: 'Sub Admin',
      url: '/Super-Admin/Admin-Master',
      icon: 'icon-list',
      IsSuperAdmin: true,
      IsRootAdmin: false,
      IsAdmin:false
    },
    {
      id:'NavItem12',
      name: 'Updates',
      url: '/Super-Admin/Updates',
      icon: 'icon-list',
      IsSuperAdmin: true,
      IsRootAdmin: true,
      IsAdmin:true
    },
    {
      id:'NavItem13',
      name: 'Invoice',
      url: '/Super-Admin/Invoices',
      icon: 'icon-list',
      IsSuperAdmin: true,
      IsRootAdmin: true,
      IsAdmin:false
    },
    {
      id:'NavItem5',
      name: 'Change Password',
      url: '/Super-Admin/change-password/1',
      icon: 'fa fa-key',
      IsSuperAdmin: true,
      IsRootAdmin: true,
      IsAdmin:true
    },
    {
      id:'NavItem8',
      name: 'Settings',
      url: '/Super-Admin/Settings',
      icon: 'fa fa-gear',
      IsSuperAdmin: true,
      IsRootAdmin: true,
      IsAdmin:false
    },

    


      ];
      UserName:string;
      UserEmail:string;
  constructor(private route: ActivatedRoute,
    private toastr: ToastrService,
    private AdminService: AdminService,
     private LogoutService: LogoutService,
      private router: Router) { }

  ngOnInit(): void {
    this.UserName = localStorage.getItem('name');
    this.UserEmail = localStorage.getItem('email');

    
      var i = 0;
      while(i< this.navMenuItems.length)
      {
        
        if(localStorage.getItem('IsSuperAdmin') == 'true'){
          if(this.navMenuItems[i]['IsSuperAdmin'] != true)
          {
            this.navMenuItems.splice(i, 1);
            i=0;
            continue;
          }  
        }else if(localStorage.getItem('IsAdmin') == 'true'){
          if(this.navMenuItems[i]['IsAdmin'] != true)
          {
            this.navMenuItems.splice(i, 1);
            i=0;
            continue;
          }  
        }else if(localStorage.getItem('IsRootAdmin') == 'true'){
          if(this.navMenuItems[i]['IsRootAdmin'] != true)
          {
            this.navMenuItems.splice(i, 1);
            i=0;
            continue;
          }  
        }
        

        i++;
      }
    
   // debugger;
    /*
    if(localStorage.getItem('IsSuperAdmin') == 'true')
    {
      var i = 0;
      while(i< this.navMenuItems.length)
      {
        if(this.navMenuItems[i]['IsAdmin'] == true)
        {
          this.navMenuItems.splice(i, 1);
          i=0;
          continue;
        }
        i++;
      }
    }

    if(localStorage.getItem('IsAdmin') == 'true')
    {
      var i = 0;
      while(i< this.navMenuItems.length)
      {
        if(this.navMenuItems[i]['IsSuperAdmin'] == true)
        {
          this.navMenuItems.splice(i, 1);
          i=0;
          continue;
        }

        i++;        
      }
    }
    */
    
    $.getScript('/assets/js/misc.js');


    this.isRootAdmin = localStorage.getItem('IsRootAdmin');
      this.isSuperAdmin = localStorage.getItem('IsSuperAdmin');
      this.isAdmin = localStorage.getItem('IsAdmin');


      if(this.isRootAdmin == "false"){
          this.id = setInterval(() => {
            this.getBadges(); 
          }, 5000);
      }

  }


  getBadges(){
    console.log(this.isRootAdmin+"---->root admin")
      this.AdminService.AdminInfo()
        .subscribe(data => {
          console.log(data.result);
          //localStorage.setItem('admininfo', data.result);
            if(data.result.important_updates_unseen_count == 0){
              data.result.important_updates_unseen_count = "";
            }
            $('.badge').html(data.result.important_updates_unseen_count);
            var message = "";

            const last_date = data.result.last_date_of_payment.split("-").reverse().join("/");;
            const amount    = data.result.total_amount_due;

            

            if(this.isSuperAdmin == "true"){
                if(data.result.is_bill_due == 1){
                    message = "You has a due amount of Rs."+amount+" so please make your payment before "+last_date+" to avoid MOVAL Subscription expiry";
                }

            }else if(this.isAdmin == "true"){
              if(data.result.is_bill_due == 1){
                  if(data.result.is_show_message_to_subadmin == 1){
                      message = "Your MOVAL subscription is under extension period. Your Subscription will be expired on ("+last_date+")";
                  }
              }

            } 
            $('.messagebilling').html(message);


          

        },
      error => {
        localStorage.setItem('admininfo', "");

          if(this.isSuperAdmin == "true" || this.isAdmin == "true"){
              if (error.status == 401) {
                console.log(error.message);
                this.toastr.warning(error.error.result.error, 'Oops');
                this.router.navigate(['/login']);
              }          

          } 
      });
  }


  toggleMinimize(e) {
    this.sidebarMinimized = e;
  }


  Logout(){
    //window.location.href = '/#/login';
    //this.router.navigate(['/login']);
    //debugger;
    this.LogoutService.Logout()
      .subscribe(data => {
        localStorage.clear();
        window.location.href = '/#/login';
        console.log(data.message)
      },
      error => {
        //debugger;
        localStorage.clear();
        if (error.status == 401) {
          window.location.href = '/#/login';
         // this.router.navigate(['/login']);
        }
        });
  }

  MenuSelectionManagement(id)
  {
    $('.nav-item .nav-link').removeClass('SideBarActiveMenu');
    $('#' + id).addClass('SideBarActiveMenu');  
  }

   ngOnDestroy() {
    if(this.id){
      clearInterval(this.id);
    }
  }
  
  
}




