import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, from } from 'rxjs';
import { ChangePassword } from '../../../Interface/SuperAdmin/change-password';
import { Constant } from '../../Models/constant';

@Injectable({
  providedIn: 'root'
})
export class ChangepasswordService {
  private url: string;
  Constants = new Constant();
  ChangePassword:Observable<ChangePassword[]>

  private change_password_root_admin = this.Constants.localhost_url + 'api/super-admin/change-password'

  private password = this.Constants.localhost_url + 'api/admin/change-password'
  private create_password = this.Constants.localhost_url + 'api/admin/set-password'


//this.IsRootAdmin
  constructor(private httpClient: HttpClient) { 

  }



  changepassword(changepasswordata:Object): Observable<any> {

    let header = new HttpHeaders().set(
      'Authorization', `Bearer ${localStorage.getItem('token')}`
    );

    var IsRootAdmin      = localStorage.getItem('IsRootAdmin');

    if(IsRootAdmin ==  'true'){
     return this.httpClient.post(`${this.change_password_root_admin}`,changepasswordata,{ headers: header }); 
    }
    return this.httpClient.post(`${this.password}`,changepasswordata,{ headers: header });
  }


  createPassoword(changepasswordata:Object): Observable<any> {
    let header = new HttpHeaders().set(
      'Authorization', `Bearer ${localStorage.getItem('token')}`
    );
    return this.httpClient.post(`${this.create_password}`,changepasswordata,{ headers: header });
  }


}
