import { ModalDirective } from 'ngx-bootstrap/modal';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder,FormControl,AbstractControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from "@angular/router";
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { timeout } from 'rxjs/operators';
import swal from 'sweetalert2';
import { ToastrService } from 'ngx-toastr';
import { ChangepasswordService } from '../Shared/AdminService/ChangePassword/changepassword.service';


@Component({
  selector: 'app-create-password',
  templateUrl: './create-password.component.html',
  styleUrls: ['./create-password.component.scss']
})

export class CreatePasswordComponent implements OnInit {
  password_new = "";
  cpassword = "";

  password_error = "";
  cpassword_error = "";
  
  constructor( private fb: FormBuilder, 
    private toastr: ToastrService,
    private router: Router, 
    private route:ActivatedRoute,
    private ChangepasswordService: ChangepasswordService) { }


  ngOnInit(): void {

  }


  ChangePaassword(){
    this.password_error       = "";
    this.cpassword_error      = "";


    if(this.password_new == ""){
      this.password_error       = this.password_error+'<br>'+'Please enter password';
    }

    if(this.password_new.length < 6){
      this.password_error      = this.password_error+'<br>'+'Password number must be at least 6 characters';
    }


    if(this.password_new != this.cpassword){
      this.cpassword_error      = this.cpassword_error+'<br>'+'Password not match';
    }



    if(this.password_error.trim() == "" && this.cpassword_error.trim() == ""){

      const parameter = {
        password: this.password_new,
        password_confirmation: this.cpassword,
        platform:this.route.snapshot.paramMap.get('platform')
      };
 
        (<HTMLInputElement>document.getElementById('loader')).style.display = 'block';
        this.ChangepasswordService.createPassoword(parameter)
          .subscribe(data => {
            if (data.success == true) {
              this.toastr.success(data.message,'Sucess');
              this.router.navigate(['/login']);
              //this.reloadCurrentRoute();
            }
            else {
              this.toastr.warning(data.message);
            }
            (<HTMLInputElement>document.getElementById('loader')).style.display = 'none';
          },
          error => {
//            debugger;
            if (error.status == 401) {
              this.password_error = error.error.result["error"] == undefined ? '' : error.error.result["error"];
              //this.router.navigate(['/login']);
            }
            else if (error.status == 422) {
              this.password_error = error.error.errors["old_password"] == undefined ? '' : error.error.errors["old_password"];
              this.cpassword_error = error.error.errors["password"] == undefined ? '' : error.error.errors["password"];
            }
            else
             {
              this.toastr.warning(error.error.result["error"], 'Oops');
            }            
            (<HTMLInputElement>document.getElementById('loader')).style.display = 'none';
          });





    }


  }


}
