import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, from } from 'rxjs';
import { AddAdmin } from '../../../Interface/Admin/AddAdmin/add-admin';
import { Constant } from '../../Models/constant';

@Injectable({
  providedIn: 'root'
})
export class AdminService {
  AddAdmin:Observable<AddAdmin[]>
  private url: string;
  Constants = new Constant();

  private addAdminnew = this.Constants.localhost_url + 'api/admin'
  private adminlist = this.Constants.localhost_url + 'api/admin'
  private admindetail = this.Constants.localhost_url + 'api/admin/'
  private admineditdetail = this.Constants.localhost_url + 'api/admin/'
  private admidelete = this.Constants.localhost_url + 'api/admin/'
  private sentCred = this.Constants.localhost_url + 'api/admin-resend-credentials/'
  private removeJobFile = this.Constants.localhost_url + 'api/admin-delete-jobfiles/'
  private adminstatus = this.Constants.localhost_url + 'api/admin/'

  private adminUpdates = this.Constants.localhost_url + 'api/important-updates?all=1&search_keyword='
 
  private sentUpdate = this.Constants.localhost_url + 'api/important-updates'

  private adminInfo = this.Constants.localhost_url + 'api/admin-info'
  private adminInvoice = this.Constants.localhost_url + 'api/admin-invoice-list'

  private superAdminInvoice = this.Constants.localhost_url + 'api/super-admin/admin-invoice-list'

  private paymentReceived = this.Constants.localhost_url + 'api/super-admin/payment-done'

  private adminPaymentLink = this.Constants.localhost_url + 'api/admin-payment-link'
  //29
 
  constructor(private httpClient: HttpClient) { }
 
  AdminRemoveData(id:string,datec:string): Observable<any> {
    let header = new HttpHeaders().set(
      'Authorization', `Bearer ${localStorage.getItem('token')}`
    );
    return this.httpClient.get(`${this.removeJobFile}`+id+'/'+datec, { headers: header });
  }


  GeneratePaymentLink(invoiceId:string): Observable<any> {
    let header = new HttpHeaders().set(
      'Authorization', `Bearer ${localStorage.getItem('token')}`
    );
    //return this.httpClient.post(`${this.adminPaymentLink}`+'/'+invoiceId, { headers: header });
    return this.httpClient.post(`${this.adminPaymentLink}`+'/'+invoiceId,{},{ headers: header });
  }


  MarkPaymentReceived(id:string,amount:string): Observable<any> {
    let header = new HttpHeaders().set(
      'Authorization', `Bearer ${localStorage.getItem('token')}`
    );
    return this.httpClient.post(`${this.paymentReceived}`,{"invoice_id":id,"pay_amount":amount},{ headers: header });
    //return this.httpClient.get(`${this.paymentReceived}`+'/'+id, { headers: header });
  }


  getSuperAdminInvoiceList(SearchTxt:string,FromDate:string,ToDate:string): Observable<any> {
    let header = new HttpHeaders().set(
      'Authorization', `Bearer ${localStorage.getItem('token')}`
    );
    //return this.httpClient.get(`${this.adminInvoice}`'?search_keyword='+ SearchTxt +'&from_date='+ FromDate +'&to_date='+ ToDate , { headers: header });

    return this.httpClient.get(`${this.superAdminInvoice + '?status=pending&search_keyword='+ SearchTxt +'&from_date='+ FromDate +'&to_date='+ ToDate }`,{ headers: header });
    
  }


  getAdminInvoiceList(SearchTxt:string,FromDate:string,ToDate:string): Observable<any> {
    let header = new HttpHeaders().set(
      'Authorization', `Bearer ${localStorage.getItem('token')}`
    );

    return this.httpClient.get(`${this.adminInvoice + '?status=pending&search_keyword='+ SearchTxt +'&from_date='+ FromDate +'&to_date='+ ToDate  }`,{ headers: header });
    //return this.httpClient.get(`${this.adminInvoice}`'?search_keyword='+ SearchTxt +'&from_date='+ FromDate +'&to_date='+ ToDate , { headers: header });
  }



  AdminInfo(): Observable<any> {
    let header = new HttpHeaders().set(
      'Authorization', `Bearer ${localStorage.getItem('token')}`
    );
    return this.httpClient.get(`${this.adminInfo}?platform=1`, { headers: header });
  }


  UpdateDelete(id:string): Observable<any> {
    let header = new HttpHeaders().set(
      'Authorization', `Bearer ${localStorage.getItem('token')}`
    );
    return this.httpClient.delete(`${this.sentUpdate}`+'/'+id, { headers: header });
  }


  SentUpdate(dataObj:Object): Observable<any> {
    let header = new HttpHeaders().set(
      'Authorization', `Bearer ${localStorage.getItem('token')}`
    );
    return this.httpClient.post(`${this.sentUpdate}`,dataObj,{ headers: header });
  }

  GetRootUpdates(SearchTxt:string): Observable<any> {
    let header = new HttpHeaders().set(
      'Authorization', `Bearer ${localStorage.getItem('token')}`
    );
    return this.httpClient.get(`${this.adminUpdates+ SearchTxt}`, { headers: header });
  }


  AddAdminnew(AddAdmindata:Object): Observable<any> {
    let header = new HttpHeaders().set(
      'Authorization', `Bearer ${localStorage.getItem('token')}`
    );
    return this.httpClient.post(`${this.addAdminnew}`,AddAdmindata,{ headers: header });
  }

  Adminlist(PageNo:string,SearchTxt:string,FromDate:string,ToDate:string): Observable<any> {
    let header = new HttpHeaders().set(
      'Authorization', `Bearer ${localStorage.getItem('token')}`
    );
    return this.httpClient.get(`${this.adminlist + '?status=pending&platform=1&search_keyword='+ SearchTxt +'&from_date='+ FromDate +'&to_date='+ ToDate +'&page=' + PageNo }`,{ headers: header });
  }

  AdminDetail(id:string): Observable<any> {
    let header = new HttpHeaders().set(
      'Authorization', `Bearer ${localStorage.getItem('token')}`
    );
    return this.httpClient.get(`${this.admindetail}`+id+"?platform=1", { headers: header });
  }

  AdminEditDetail(AddAdmindata:Object,id:string): Observable<any> {
    let header = new HttpHeaders().set(
      'Authorization', `Bearer ${localStorage.getItem('token')}`
    );
    var isRootAdmin = localStorage.getItem('IsRootAdmin');
    //if(isRootAdmin == "true"){
      return this.httpClient.post(`${this.admineditdetail}`+id+"?_method=PUT",AddAdmindata, { headers: header });
    //}
    //  return this.httpClient.put(`${this.admineditdetail}`+id,AddAdmindata, { headers: header });
    //
    
  }

  AdmiDelete(id:string): Observable<any> {
    let header = new HttpHeaders().set(
      'Authorization', `Bearer ${localStorage.getItem('token')}`
    );
    return this.httpClient.delete(`${this.admidelete}`+id+"?platform=1", { headers: header });
  }

  AdmiSc(id:string): Observable<any> {
    let header = new HttpHeaders().set(
      'Authorization', `Bearer ${localStorage.getItem('token')}`
    );
    return this.httpClient.get(`${this.sentCred}`+id, { headers: header });
  }


  Adminstatus(Status:string,id:string): Observable<any> {
    let header = new HttpHeaders().set(
      'Authorization', `Bearer ${localStorage.getItem('token')}`
    );
    return this.httpClient.get(`${this.adminstatus}` + id + "/" + Status,{ headers: header });
  }
  
}
