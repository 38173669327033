import { getStyle, hexToRgba } from '@coreui/coreui/dist/js/coreui-utilities';
import { CustomTooltips } from '@coreui/coreui-plugin-chartjs-custom-tooltips';
import { ChartsModule } from 'ng2-charts';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, AbstractControl, FormGroup, NgForm, Validators, FormsModule } from '@angular/forms';
import { Router, ActivatedRoute } from "@angular/router";
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { timeout } from 'rxjs/operators';
import swal from 'sweetalert2';
import { ToastrService } from 'ngx-toastr';
import { Input, ViewEncapsulation } from '@angular/core';
import { flagSet } from '@coreui/icons';
import { DatePipe } from '@angular/common';
import { DashboardService } from '../../Shared/DashboardService/dashboard.service';
import * as $ from 'jquery';

import { CommonModule } from '@angular/common';  
import { BrowserModule } from '@angular/platform-browser';

@Component({
  templateUrl: 'dashboard.component.html'
})
export class DashboardComponent implements OnInit {
  @ViewChild('f', { static: true }) floatingLabelForm: NgForm;
  @ViewChild('vform', { static: true }) validationForm: FormGroup;

  Dashboarddata: any[];
  Employee:any[];
  Client:any[];
  Admin:any[];

   AdminCount: '';
   NotYetAssigned: '';
   Assigned: '';
   Tobeapproved: '';
   Rejected: '';
   Approved: '';
   Completed: '';
   EmployeeCount: '';

   isRootAdmin: String = "false";
   parent_id: String = "";


  constructor(
    private DashboardService: DashboardService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private router: Router) { 

  }

  ngOnInit(): void {
    this.isRootAdmin = localStorage.getItem('IsRootAdmin');
    this.parent_id = localStorage.getItem('parent_id');
    
    this.dashborddata();
    this.MenuSelectionManagement('NavItem7');
  }



  dashborddata() {
   
    (<HTMLInputElement>document.getElementById('loader')).style.display = 'block';
    this.DashboardService.Dashborddatalist()
      .subscribe(data => {

        if(this.isRootAdmin == "true"){
          this.AdminCount = data.result.admin_count

        }else{
          this.Dashboarddata = data.result.jobs_count;
          this.Employee = data.result.employee_count;
          this.Client = data.result.client_count
          this.Admin = data.result.subadmin_count;
          this.NotYetAssigned = this.Dashboarddata['created'];
          this.Assigned = this.Dashboarddata['pending'];
          this.Tobeapproved = this.Dashboarddata['submitted'];
          this.Rejected = this.Dashboarddata['rejected'];
          this.Approved = this.Dashboarddata['approved'];
          this.Completed = this.Dashboarddata['finalized'];
              
          
          
        }

        
        
       // $.getScript('assets/js/Easypichart/easypiechart.js'); 

        


        (<HTMLInputElement>document.getElementById('loader')).style.display = 'none';
      },
        error => {
          if (error.status == 401) {
            console.log(error.message)
            this.router.navigate(['/login']);
          }
          (<HTMLInputElement>document.getElementById('loader')).style.display = 'none';
        });
  }

  MenuSelectionManagement(id) {
    $('.nav-item .nav-link').removeClass('SideBarActiveMenu');
    $('#' + id).addClass('SideBarActiveMenu');
  }
}
