import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
// Import Containers
import { DefaultLayoutComponent } from './containers';
import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
import { LoginComponent } from './views/login/login.component';
import { CreatePasswordComponent } from './create-password/create-password.component';
import { RegisterComponent } from './views/register/register.component';
import { DashboardComponent }from './views/dashboard/dashboard.component';
import { AuthguardGuard } from './Shared/Auth/authguard.guard';
import { PlatformGuard } from './Shared/Auth/platform.guard';
import { SurveyLinkComponent } from './motor-survey/Jobs/survey-link/survey-link.component';
import { ReinspectionSurveyLinkComponent } from './motor-survey/Jobs/reinspection-survey-link/reinspection-survey-link.component';
import { RepairSurveyLinkComponent } from './motor-survey/Jobs/repair-survey-link/repair-survey-link.component';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  { path: 'login',component: LoginComponent,data: {title: 'login'}},
  { path: 'create-password/:platform',component: CreatePasswordComponent,data: {title: 'create-password'}},
  { path: 'dashbord', component: DashboardComponent},
  { path: '404',component: P404Component,data: { title: 'Page 404'}},
  { path: '500',component: P500Component,data: {title: 'Page 500'}},
  { path: 'register',component: RegisterComponent,data: {title: 'Register Page'}},
  { path:  'Survey-Link/:id',component:SurveyLinkComponent},
  { path:  'Repair-Survey-Link/:id',component:RepairSurveyLinkComponent},
  { path:  'Reinspection-Survey-Link/:id',component:ReinspectionSurveyLinkComponent},
  { path: '',component: DefaultLayoutComponent,canActivate: [PlatformGuard],data: { title: '',platform:'1'},
    children: [
      {
        path: 'base',
        loadChildren: () => import('./views/base/base.module').then(m => m.BaseModule)
      },
      {
        path: 'buttons',
        loadChildren: () => import('./views/buttons/buttons.module').then(m => m.ButtonsModule)
      },
      {
        path: 'charts',
        loadChildren: () => import('./views/chartjs/chartjs.module').then(m => m.ChartJSModule)
      },
      {
        path: 'dashboard',
        loadChildren: () => import('./views/dashboard/dashboard.module').then(m => m.DashboardModule)
      },
      // {
      //   path: 'login',
      //   loadChildren: () => import('./views/login/login.module').then(m => m.LoginModule)
      // },
      {
        path: 'icons',
        loadChildren: () => import('./views/icons/icons.module').then(m => m.IconsModule)
      },
      {
        path: 'notifications',
        loadChildren: () => import('./views/notifications/notifications.module').then(m => m.NotificationsModule)
      },
      {
        path: 'theme',
        loadChildren: () => import('./views/theme/theme.module').then(m => m.ThemeModule)
      },
      {
        path: 'widgets',
        loadChildren: () => import('./views/widgets/widgets.module').then(m => m.WidgetsModule)
      },
      {
        path: 'Super-Admin',
        loadChildren: () => import('./Super-Admin/super-admin.module').then(m => m.SuperAdminModule)
      },
      {
        path: 'Clients',
        loadChildren: () => import('./Clients/clients.module').then(m => m.ClientsModule)
      },
      {
        path: 'Employee',
        loadChildren: () => import('./Employee/employee.module').then(m => m.EmployeeModule)
      },
      {
        path: 'Jobs',
        loadChildren: () => import('./Jobs/jobs.module').then(m => m.JobsModule)
      },
      
    ]
  },
  {
    path: 'Motor-Survey',
    loadChildren: () => import('./motor-survey/motor-survey.module').then(m => m.MotorSurveyModule)
  },
  { path: '**', component: P404Component }
];

@NgModule({
  imports: [ RouterModule.forRoot(routes) ],
  exports: [ RouterModule ]
})
export class AppRoutingModule {}
