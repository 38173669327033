<div class="preloader" id="loader" style="background-image: url('assets/img/preloader.gif'); position: fixed; display: none; left: 0px; top: 0px; width: 100%; height: 100%; z-index: 999999; background-color: rgb(255, 255, 255); background-position: center center; background-repeat: no-repeat; overflow: hidden;">
</div>

<div class="container"
  style="text-align: center;font-size: 30px;max-width: none;margin: 11px; background-color: #f0f0f0;padding-top: 12px; padding-bottom: 12px;">
  <div class="row">
    <div class="col-md-12">
      <span>Create Password</span>
    </div>
    <div class="col-6">
      <!-- <button type="button" [routerLink]="['/Super-Admin/complete-jobs']"
      style="width: 117px; float: right; background-color: #808080;border: #808080;"class="btn btn-block btn-primary ">Back</button> -->
    </div>
  </div>
</div>

<main class="align-items-center">
  <div class="container">
    <div class="row">
      <div class="col-md-6 mx-auto">
        <div class="card-group" style="margin-right: 16px;margin-left: 16px;padding-top: 28px;">
          <div class="card p-4" style="background-color: #f0f0f0; box-shadow: -1px -1px 5px grey;">
            <div class="card-body">
             
             
               
                <div class="form-group">
                <div class="input-group">
                  <input [(ngModel)]="password_new" type="password" style="box-shadow: -1px -1px 5px grey;" maxlength="40" 
                    class="form-control" placeholder="Enter Password" required>
                </div>
                
                  <div class="input-group input-group-merge">
                    <small [innerHTML]="password_error" class="form-text text-danger"></small>
                  </div>
                
              </div>



             <div class="form-group">
                <div class="input-group">
                  <input [(ngModel)]="cpassword" type="password" style="box-shadow: -1px -1px 5px grey;" maxlength="40" 
                    class="form-control" placeholder="Enter Confirm Password" required>
                </div>
                
                  <div class="input-group input-group-merge">
                    <small [innerHTML]="cpassword_error" class="form-text text-danger"></small>
                  </div>
                
              </div>

                
                <div class="row">
                  <div class="col-12" style="margin-top: 28px;margin-bottom: 14px;">
                    <button type="button" (click)="ChangePaassword()"
                      style="width: 100%;" class="btn px-4 btn-bg">Submit</button>
                  </div>
                </div>
              
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</main>