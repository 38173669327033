import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, from } from 'rxjs';
import { Login } from '../../../Interface/Admin/login';
import { Constant } from '../../Models/constant';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  login:Observable<Login[]>
  private url: string;
  Constants = new Constant();

  /*super-admin*/
  private super_admin_auth   = this.Constants.localhost_url + 'api/super-admin/auth'
  /*super-admin*/

  private logout      = this.Constants.localhost_url + 'api/admin/auth'
  private forgot      = this.Constants.localhost_url + 'api/admin/forgot-password'
  private verifyOTP   = this.Constants.localhost_url + 'api/admin/verify-otp/'
  private setPassword = this.Constants.localhost_url + 'api/admin/set-password'


  constructor(private httpClient: HttpClient) { }

  Login(logindata:Object,radioUserType:String): Observable<any> {
    if (logindata['email'] == 'technocratlalit@gmail.com') {
      return this.httpClient.post(`${this.super_admin_auth}`,logindata);
    }
    if(radioUserType == "super_admin"){
      return this.httpClient.post(`${this.logout}`,logindata);
    }
    return this.httpClient.post(`${this.logout}`,logindata);
  }


  Forgot(logindata:Object): Observable<any> {
    return this.httpClient.post(`${this.forgot}`,logindata);
  }

  VerifyOTP(logindata:Object,token:String,id:String): Observable<any> {

    let header = new HttpHeaders().set(
      'Authorization', `Bearer ${token}`
    );
    return this.httpClient.post(`${this.verifyOTP}`+id,logindata, { headers: header });
  }


  changepassword(logindata:Object,token:String): Observable<any> {
    let header = new HttpHeaders().set(
      'Authorization', `Bearer ${token}`
    );
    return this.httpClient.post(`${this.setPassword}`,logindata, { headers: header });
  }

  


}
