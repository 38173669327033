<div class="preloader" id="loader" style="background-image: url('assets/img/preloader.gif'); position: fixed; display: none; left: 0px; top: 0px; width: 100%; height: 100%; z-index: 999999; background-color: rgb(255, 255, 255); background-position: center center; background-repeat: no-repeat; overflow: hidden;"></div>


<app-header
  [navbarBrandRouterLink]="['/Super-Admin/complete-jobs']"
  [fixed]="true"
  [navbarBrandFull]=" {src: '/assets/img/brand/logo.png', width: 200, height: 54, alt: 'CoreUI Logo'}"
  [navbarBrandMinimized]="{src: '/assets/img/brand/sygnet.svg', width: 30, height: 30, alt: 'CoreUI Logo'}" 
  [sidebarToggler]="'lg'" 
  [asideMenuToggler]="'lg'">

  <div style="color:#5d87ff" class="messagebilling"></div>
  
  <ul class="nav navbar-nav ml-auto">
    <li class="nav-item dropdown" dropdown placement="bottom right">
      <a class="nav-link" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false" dropdownToggle (click)="false">
        <img src="assets/img/brand/user.jpg" class="img-avatar" alt="admin@bootstrapmaster.com"/>
      </a>
      <div class="dropdown-menu dropdown-menu-right" *dropdownMenu aria-labelledby="simple-dropdown">
        <a class="dropdown-item"  href="javascript:"><i class="fa fa-user"></i>{{UserName}}</a>
        <a class="dropdown-item"  href="javascript:"><i class="fa fa-envelope"></i>{{UserEmail}}</a>
        <a class="dropdown-item" (click)="Logout()" href="javascript:"><i class="fa fa-lock"></i> Logout</a>
      </div>
    </li>
  </ul>
</app-header>
<div class="app-body">
  <app-sidebar #appSidebar colorScheme="light" [fixed]="true" [display]="'lg'" [minimized]="sidebarMinimized" (minimizedChange)="toggleMinimize($event)">
    <!-- <app-sidebar-nav [navItems]="navMenuItems" [perfectScrollbar] [disabled]="appSidebar.minimized"></app-sidebar-nav> -->
    
    <nav class="sidebar-nav">
      <ul class="nav">
          <li class="nav-item" *ngFor="let data of navMenuItems;  let indexOfelement=index;">
              <a class="active nav-link ng-star-inserted" id="{{data.id}}" (click)="MenuSelectionManagement(data.id)" [routerLink]="[data.url]">
                  <i class="nav-icon ng-star-inserted {{data.icon}}"></i> {{data.name}}                    
                  <span *ngIf="data.id == 'NavItem12' " class="badge"></span>
              </a>
          </li>
      </ul>
  </nav>


  </app-sidebar>


  <!-- <div class="sidebar">
    <nav class="sidebar-nav">
        <ul class="nav">
            <li class="nav-item" *ngFor="let data of navMenuItems;  let indexOfelement=index;">
                <a class="active nav-link ng-star-inserted" id="{{data.id}}" (click)="MenuSelectionManagement(data.id)" [routerLink]="[data.url]">
                    <i class="nav-icon ng-star-inserted {{data.icon}}"></i> {{data.name}}                    
                </a>
            </li>
        </ul>
    </nav>
    
</div>  -->



  <main class="main" style="font-size: large;font-weight: 500;">
     
    <div class="container-fluid" style="background-color: #ffffff;padding-top: 12px;padding-bottom: 68px;">
      <router-outlet></router-outlet>
    </div>
  </main>
</div>

<!-- <app-footer style="background-color: #f0f0f0;height:10px !important;">
   <span><a href="">Moval&nbsp;Technocratlalit</a></span>
   <span class="ml-auto"><a href=""></a></span>
</app-footer> -->
