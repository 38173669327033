import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { LoginService } from '../../Shared/AdminService/login/login.service';
import { Login } from '../../Interface/Admin/login';
import { ToastrModule } from 'ngx-toastr';
import { ToastrService } from 'ngx-toastr';
import { ConfirmedValidator } from '../../Super-Admin/change-password/confirmed.validator';
import {
  FormBuilder,
  FormControl,
  AbstractControl,
  FormGroup,
  NgForm,
  Validators,
} from '@angular/forms';
import * as CryptoJS from 'crypto-js';

@Component({
  selector: 'app-dashboard',
  templateUrl: 'login.component.html',
})
export class LoginComponent {
  @ViewChild('f', { static: true }) floatingLabelForm: NgForm;
  @ViewChild('vform', { static: true }) validationForm: FormGroup;

  Loginforms: FormGroup;
  key = '';
  submitted = false;

  show_login = true;
  show_forgot = false;
  show_otp = false;
  show_change_password = false;

  old_passwordError = '';
  passwordError = '';

  userType = 'SUPER_ADMIN';

  otpResponse = {
    token: '',
    id: '',
  };

  verifyResponse = {
    token: '',
    id: '',
  };

  Otpforms: FormGroup;
  Forgotforms: FormGroup;
  changepasswordform: FormGroup;

  get LoginEmail() {
    return this.Loginforms.get('email');
  }

  showLogin() {
    this.show_login = true;
    this.show_forgot = false;
    this.show_otp = false;
    this.show_change_password = false;
    this.submitted=false;
  }

  showForgot() {
    this.show_login = false;
    this.show_forgot = true;
    this.show_otp = false;
    this.show_change_password = false;
    this.submitted=false;
  }

  verifyOtp() {
    this.show_login = false;
    this.show_forgot = false;
    this.show_otp = true;
    this.submitted=false;
    this.show_change_password = false;
  }

  showChangePassword() {
    this.show_login = false;
    this.show_forgot = false;
    this.show_otp = false;
    this.show_change_password = true;
  }

  constructor(
    private fb: FormBuilder,
    private toastr: ToastrService,
    private service: ToastrModule,
    private loginservice: LoginService,
    private router: Router
  ) {}

  get f() {
    return this.Loginforms.controls;
  }
  get fo() {
    return this.Forgotforms.controls;
  }
  get foo() {
    return this.Otpforms.controls;
  }
  get fc() {
    return this.changepasswordform.controls;
  }

  ngOnInit(): void {
    localStorage.clear();
    this.Loginforms = new FormGroup({
      email: new FormControl('', [
        Validators.required,
        Validators.pattern('^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,4}$'),
      ]),
      Password: new FormControl('', [Validators.required, this.spaceValidator]),
      platform: new FormControl('', [Validators.required]),
      radioUserType: new FormControl('super_admin', [Validators.required]),
    });

    this.Forgotforms = new FormGroup({
      user_email: new FormControl('', [
        Validators.required,
        Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'),
      ]),
      platform:new FormControl('', [Validators.required])
    });

    this.Otpforms = new FormGroup({
      user_otp: new FormControl('', [Validators.required, this.spaceValidator]),
    });

    this.changepasswordform = this.fb.group(
      {
        password: new FormControl('', [Validators.required]),
        password_confirmation: new FormControl('', [Validators.required]),
      },
      {
        validator: ConfirmedValidator('password', 'password_confirmation'),
      }
    );
  }

  public validateControl(controlName: string) {
    if (
      this.Loginforms.controls[controlName].invalid &&
      this.Loginforms.controls[controlName].touched
    ) {
      return true;
    }
    return false;
  }

  public hasError(controlName: string, errorName: string) {
    if (this.Loginforms.controls[controlName].hasError(errorName)) {
      return true;
    }
    return false;
  }

  public onSignForgot(Forgotforms) {
    this.submitted=true;
    if (this.Forgotforms.valid) {
      const logindata = {
        email: Forgotforms.user_email,
        platform:Forgotforms.platform
      };

      (<HTMLInputElement>document.getElementById('loader')).style.display =
        'block';
      this.loginservice.Forgot(logindata).subscribe(
        (data) => {
            this.submitted = false;
          if (data.success == true) {
            this.toastr.success(data.message);
            this.otpResponse = data.result;
            this.verifyOtp();
            console.log(data);
            
          } else {
            this.toastr.warning(data.message);
          }
          (<HTMLInputElement>document.getElementById('loader')).style.display =
            'none';
        },
        (error) => {
          this.toastr.warning(error.error.message);
          this.submitted=false;
          (<HTMLInputElement>document.getElementById('loader')).style.display =
            'none';
        }
      );
    }
  }

  onVerifyOTP(Otpforms) {
    //Otpforms.user_otp
    this.submitted=true;
    if (this.Otpforms.valid) {
      const logindata = {
        otp: Otpforms.user_otp,
        platform:this.Forgotforms.value['platform']
      };

      (<HTMLInputElement>document.getElementById('loader')).style.display =
        'block';
      console.log(this.otpResponse.token);
      this.submitted=false;
      this.loginservice
        .VerifyOTP(logindata, this.otpResponse.token, this.otpResponse.id)

        .subscribe(
          (data) => {
            this.submitted=false;
            if (data.success == true) {
              this.toastr.success('OTP verified please create new password');
              this.verifyResponse = data.result;
              this.showChangePassword();
              console.log(data);
            } else {
              this.toastr.warning(data.message);
            }
            (<HTMLInputElement>(
              document.getElementById('loader')
            )).style.display = 'none';
          },
          (error) => {
            this.toastr.warning(error.error.message);
            this.submitted=false;
            (<HTMLInputElement>(
              document.getElementById('loader')
            )).style.display = 'none';
          }
        );
    }
  }

  public ChangePaassword(changepasswordFormvalue) {
    //      debugger
    this.submitted = true;
    this.old_passwordError = '';
    this.passwordError = '';

    if (this.changepasswordform.valid) {
      const changepasswordata = {
        password: changepasswordFormvalue.password,
        password_confirmation: changepasswordFormvalue.password_confirmation,
        platform:this.Forgotforms.value['platform']
      };

      (<HTMLInputElement>document.getElementById('loader')).style.display =
        'block';

      this.loginservice
        .changepassword(changepasswordata, this.verifyResponse.token)
        .subscribe(
          (data) => {
            if (data.success == true) {
              this.toastr.success('Password changed successfully', 'Sucess');

              var delayInMilliseconds = 1500; //1 second
              setTimeout(function () {
                window.location.reload();
              }, delayInMilliseconds);

              //this.router.navigate(['/login']);
              //this.reloadCurrentRoute();
            } else {
              this.toastr.warning(data.message);
            }
            (<HTMLInputElement>(
              document.getElementById('loader')
            )).style.display = 'none';
          },
          (error) => {
            (<HTMLInputElement>(
              document.getElementById('loader')
            )).style.display = 'none';
            
            this.toastr.warning(error.error.result.error, 'Oops');
           
          }
        );
    }
  }

  public onSignup(loginFormvalue) {
    console.log(loginFormvalue.radioUserType);

    this.submitted = true;
    if (this.Loginforms.valid) {
      const logindata: Login = {
        email: loginFormvalue.email,
        radioUserType: loginFormvalue.radioUserType,
        password: loginFormvalue.Password,
        platform: loginFormvalue.platform,
      };
      (<HTMLInputElement>document.getElementById('loader')).style.display =
        'block';

      this.loginservice
        .Login(logindata, loginFormvalue.radioUserType)
        .subscribe(
          (data) => {
            var UserData = data;
            
            if (UserData.result.detail.email == 'technocratlalit@gmail.com') {
              if (UserData.success == true) {
                this.toastr.success(data.message);
                this.key = UserData.result.token;
                localStorage.setItem('token', UserData.result.token);

                localStorage.setItem('id', UserData.result.detail.id);
                localStorage.setItem('parent_id', '-1');
                localStorage.setItem('name', UserData.result.detail.name);
                localStorage.setItem('email', UserData.result.detail.email);
                localStorage.setItem('address', UserData.result.detail.address);
                localStorage.setItem('role', 'Super Admin');
                localStorage.setItem(
                  'mobile_no',
                  UserData.result.detail.mobile_no
                );
                localStorage.setItem('status', UserData.result.detail.status);
                localStorage.setItem(
                  'created_at',
                  UserData.result.detail.created_at
                );
                localStorage.setItem(
                  'updated_at',
                  UserData.result.detail.updated_at
                );
                localStorage.setItem('can_download_report', 'true');
                localStorage.setItem('platform', logindata.platform);
                localStorage.setItem('IsRootAdmin', 'true');
                localStorage.setItem('IsSuperAdmin', 'false');
                localStorage.setItem('IsAdmin', 'false');
                if (logindata.platform == '1') {
                  
                  window.location.href = '#/dashboard';
                } else {
                  window.location.href = '#/Motor-Survey/dashboard';
                }
              } else {
                this.toastr.warning(data.message);
              }
            } else {
              if (UserData.success == true) {
                this.key = UserData.result.token;
                this.toastr.success(data.message);
                localStorage.setItem('token', UserData.result.token);
                localStorage.setItem('name', UserData.result.detail.name);
                localStorage.setItem('email', UserData.result.detail.email);
                localStorage.setItem('id', UserData.result.detail.id);
                localStorage.setItem('parent_id',UserData.result.detail.parent_id);
                localStorage.setItem('address',UserData.result.detail.address);
                localStorage.setItem('mobile_no',UserData.result.detail.mobile_no);
                localStorage.setItem('status',UserData.result.detail.status);
                localStorage.setItem('created_at',UserData.result.detail.created_at);
                localStorage.setItem('updated_at',UserData.result.detail.updated_at);
                localStorage.setItem('can_download_report',UserData.result.detail.can_download_report);
                localStorage.setItem('platform', logindata.platform);
                localStorage.setItem('role', UserData.result.role.replace('_',' '));
                //            debugger;
                if (logindata.platform == '2') {
                  if (UserData.result.detail.is_set_password == 'no') {
                    return this.router.navigate(['create-password',logindata.platform]);
                  } else {
                   
                    let RoleDetails = this.encrypt(UserData.result.role);
                    localStorage.setItem('AuthInfo', RoleDetails);
                    if(UserData.result.role !=='admin' ){
                    localStorage.setItem('SubAdminBranch',JSON.stringify([UserData.result.branch_detail]))
                  }
                    return this.router.navigate(['/Motor-Survey/Jobs/Jobs/approved']);
                   
                  }
                }
                if (
                  UserData.result.detail.parent_id == '0' &&
                  logindata.platform == '1'
                ) {
                  if (UserData.result.detail.is_set_password == 'no') {
                    window.location.href = '#/create-password/'+logindata.platform;
                  } else {
                    localStorage.setItem('IsRootAdmin', 'false');
                    localStorage.setItem('IsSuperAdmin', 'true');
                    localStorage.setItem('IsAdmin', 'false');
                    window.location.href = '#/dashboard';
                  }
                } else {
                  if (UserData.result.detail.is_set_password == 'no') {
                    window.location.href = '#/create-password/'+ logindata.platform;
                  } else {
                    localStorage.setItem('IsSuperAdmin', 'false');
                    localStorage.setItem('IsRootAdmin', 'false');
                    localStorage.setItem('IsAdmin', 'true');
                    window.location.href = '#/Jobs/Jobs';
                  }
                }
              } else {
                this.toastr.warning(data.message);
              }

              console.log(UserData);
            }
            (<HTMLInputElement>(
              document.getElementById('loader')
            )).style.display = 'none';
          },
          (error) => {
            if (error.error.result.error == 'Account Inactive') {
              this.toastr.warning(
                'Your access is blocked. Please contact Moval platform admin.'
              );
            } else {
              this.toastr.warning(error.error.result.error);
            }

            (<HTMLInputElement>(
              document.getElementById('loader')
            )).style.display = 'none';
          }
        );
    }
  }

  spaceValidator(control: AbstractControl) {
    //    debugger;
    if (control && control.value && !control.value.replace(/\s/g, '').length) {
      control.setValue('');
      console.log(control.value);
      return { required: true };
    } else {
      return null;
    }
  }

  private encrypt(txt: string): string {
    return CryptoJS.AES.encrypt(txt, this.key).toString();
  }

}
