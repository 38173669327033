import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, from } from 'rxjs';
import { Constant } from '../../Models/constant';


@Injectable({
  providedIn: 'root'
})
export class LogoutService {

  private url: string;
  Constants = new Constant();

  private logout = this.Constants.localhost_url + 'api/admin/logout'
  constructor(private httpClient: HttpClient) { }

  Logout(): Observable<any> {
     let header = new HttpHeaders().set(
       'Authorization', `Bearer ${localStorage.getItem('token')}`,
     ).set('content-type', 'application/json');
    return this.httpClient.post(`${this.logout}`,{ headers: header });
  }
}
