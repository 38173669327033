import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

import { IconSetService } from '@coreui/icons-angular';
import { freeSet } from '@coreui/icons';
import { AdminService } from './Shared/AdminService/Admin/admin.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  // tslint:disable-next-line
  selector: 'body',
  template: '<router-outlet></router-outlet>',
  providers: [IconSetService,AdminService,ToastrService],
})

export class AppComponent implements OnInit {

  isRootAdmin: String = "false";
  isSuperAdmin: String = "false";
  isAdmin: String = "false";

  

  id = 0;

  constructor(
    private router: Router,
    private AdminService: AdminService,
    private toastr: ToastrService,
    public iconSet: IconSetService
  ) {
    // iconSet singleton
    iconSet.icons = { ...freeSet };
  }

  ngOnInit() {
      this.router.events.subscribe((evt) => {
        if (!(evt instanceof NavigationEnd)) {
          return;
        }
        window.scrollTo(0, 0);
      });

      // this.isRootAdmin = localStorage.getItem('IsRootAdmin');
      // this.isSuperAdmin = localStorage.getItem('IsSuperAdmin');
      // this.isAdmin = localStorage.getItem('IsAdmin');


      // if(this.isRootAdmin == "false"){
      //     this.id = setInterval(() => {
      //       this.getBadges(); 
      //     }, 5000);
      // }
  }

  ngOnDestroy() {
    if(this.id){
      clearInterval(this.id);
    }
  }


  getBadges(){
    console.log(this.isRootAdmin+"---->root admin")
      this.AdminService.AdminInfo()
        .subscribe(data => {
          console.log(data.result);
          //localStorage.setItem('admininfo', data.result);
          if(data.result.important_updates_unseen_count >= 1){
            $('.badge').html(data.result.important_updates_unseen_count);
            var message = "";

            const last_date = data.result.last_date_of_payment.split("-").reverse().join("/");;
            
            const amount    = data.result.total_amount_due;

            if(this.isSuperAdmin == "true"){
                if(data.result.is_bill_due == 1){
                    message = "You has a due amount of Rs."+amount+" so please make your payment before "+last_date+" to avoid MOVAL Subscription expiry";
                }

            }else if(this.isAdmin == "true"){
              if(data.result.is_bill_due == 1){
                  if(data.result.is_show_message_to_subadmin == 1){
                      message = "Your MOVAL subscription is under extension period. Your Subscription will be expired on ("+last_date+")";
                  }
              }

            } 
            $('.messagebilling').html(message);


          }

        },
      error => {
        localStorage.setItem('admininfo', "");

          if(this.isSuperAdmin == "true" || this.isAdmin == "true"){
              if (error.status == 401) {
                console.log(error.message);
                this.toastr.warning(error.error.message, 'Oops');
                this.router.navigate(['/login']);
              }          

          } 
      });
  }


}
