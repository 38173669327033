import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, from } from 'rxjs';
import { Constant } from '../Models/constant';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  private url: string;
  Constants = new Constant();

  private dashbordlist = this.Constants.localhost_url + 'api/dashboard'

  private dashbordlistRootAdmin = this.Constants.localhost_url + 'api/super-admin/dashboard'


  constructor(private httpClient: HttpClient) { }


  Dashborddatalist(): Observable<any> {
    let header = new HttpHeaders().set(
      'Authorization', `Bearer ${localStorage.getItem('token')}`
    );
    var isRootAdmin = localStorage.getItem('IsRootAdmin');
    if(isRootAdmin == "true"){
     return this.httpClient.get(`${this.dashbordlistRootAdmin}?platform=1`,{ headers: header }); 
    }
    return this.httpClient.get(`${this.dashbordlist}`,{ headers: header });
  }
}
