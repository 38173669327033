<div class="container" style="max-width: none;  background-color: #f0f0f0;padding-top: 12px; padding-bottom: 12px;">
  <div class="row">
    <div class="col-md-1">
      <span class="fontcolor_red">Dashboard</span>
    </div>
  </div>
</div>


<div  *ngIf="isRootAdmin=='true'" class="container-fluid"  style="margin-top: 33px;">
  <div class="row">

    <div class="col-sm-6 col-lg-3">
    <!-- Card -->
    <a class="resturant-card dashboard--card card--bg-4" href="javascript:void(0)">
       <h4 id="div_NotYetAssigned"  data-percent="0" class="title">{{AdminCount}}</h4>
       <span class="subtitle">Admin Count</span>
       <img src="assets/moval_images/4.png" alt="img" class="resturant-icon">
    </a>
    <!-- End Card -->
</div>
 </div>
</div>    

<div  *ngIf="isRootAdmin == 'false' " class="container-fluid"  style="margin-top: 33px;">



  <div class="row g-2" id="order_stats">
     <div class="col-sm-6 col-lg-3">
        <!-- Card -->
        <a class="resturant-card dashboard--card card--bg-1" href="javascript:void(0)">
           <h4 class="title">{{NotYetAssigned}}</h4>
           <span class="subtitle">Not Yet Assigned</span>
           <img src="assets/moval_images/1.png" alt="img" class="resturant-icon">
        </a>
        <!-- End Card -->
    </div>

<div class="col-sm-6 col-lg-3">
    <!-- Card -->
    <a class="resturant-card dashboard--card card--bg-2" href="javascript:void(0)">
       <h4 class="title">{{Assigned}}</h4>
       <span class="subtitle">Assigned</span>
       <img src="assets/moval_images/2.png" alt="img" class="resturant-icon">
    </a>
    <!-- End Card -->
</div>

<div class="col-sm-6 col-lg-3">
    <!-- Card -->
    <a class="resturant-card dashboard--card card--bg-3"  href="javascript:void(0)">
       <h4 class="title">{{Tobeapproved}}</h4>
       <span class="subtitle">To be approved</span>
       <img src="assets/moval_images/3.png" alt="img" class="resturant-icon">
    </a>
    <!-- End Card -->
</div>



<div class="col-sm-6 col-lg-3">
    <!-- Card -->
    <a class="resturant-card dashboard--card card--bg-4" href="javascript:void(0)">
       <h4 class="title">{{Rejected}}</h4>
       <span class="subtitle">Rejected</span>
       <img src="assets/moval_images/4.png" alt="img" class="resturant-icon">
    </a>
    <!-- End Card -->
</div>


<div class="col-sm-6 col-lg-3" style="margin-top:10px">
    <!-- Card -->
    <a class="resturant-card dashboard--card card--bg-3" href="javascript:void(0)">
       <h4 class="title">{{Approved}}</h4>
       <span class="subtitle">Approved</span>
       <img src="assets/moval_images/3.png" alt="img" class="resturant-icon">
    </a>
    <!-- End Card -->
</div>


<div class="col-sm-6 col-lg-3" style="margin-top:10px">
    <!-- Card -->
    <a class="resturant-card dashboard--card card--bg-4" href="javascript:void(0)">
       <h4 class="title">{{Completed}}</h4>
       <span class="subtitle">Completed</span>
       <img src="assets/moval_images/4.png" alt="img" class="resturant-icon">
    </a>
    <!-- End Card -->
</div>





<div class="col-12" style="margin-top:10px">
    <div class="row g-2">
        <div class="col-sm-6 col-lg-3">
            <a class="order--card h-100" href="javascript:void(0)">
                <div class="d-flex justify-content-between align-items-center">
                    <h6 class="card-subtitle d-flex justify-content-between m-0 align-items-center">
                        <img src="assets/moval_images/1s.png" alt="dashboard" class="oder--card-icon">
                        <span>Total Employee</span>
                    </h6>
                    <span class="card-title text-success">
                        {{Employee}}
                    </span>
                </div>
            </a>
        </div>

        <div class="col-sm-6 col-lg-3">
            <a class="order--card h-100" href="javascript:void(0)">
                <div class="d-flex justify-content-between align-items-center">
                    <h6 class="card-subtitle d-flex justify-content-between m-0 align-items-center">
                        <img src="assets/moval_images/2s.png" alt="dashboard" class="oder--card-icon">
                        <span>Total Client</span>
                    </h6>
                    <span class="card-title text-danger">
                        {{Client}}
                    </span>
                </div>
            </a>
        </div>

        <div *ngIf="parent_id == '0' " class="col-sm-6 col-lg-3">
            <a class="order--card h-100" href="javascript:void(0)">
                <div class="d-flex justify-content-between align-items-center">
                    <h6 class="card-subtitle d-flex justify-content-between m-0 align-items-center">
                        <img src="assets/moval_images/3s.png" alt="dashboard" class="oder--card-icon">
                        <span>Total Sub Admin</span>
                    </h6>
                    <span class="card-title text-primary">
                        {{Admin}}
                    </span>
                </div>
            </a>
        </div>

         
    </div>
</div>
                </div>

